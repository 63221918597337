import Header from "../Home/Header"
import Footer from "../Home/Footer"

import Button from 'react-bootstrap/Button'

function NavPhotosDimanche () {
  return (
    <div>
      <Header />
      <div className="core navBloc">
      <a href="/galerie/2010/Dimanche/bivouac"> <Button className='p-3' variant='warning'>Le bivouac des Auvergnats</Button></a> 
      <a href="/galerie/2010/Dimanche/arrestation"> <Button className='p-3' variant='warning'>L'arrestation</Button></a> 
      <a href="/galerie/2010/Dimanche/cortege"> <Button className='p-3' variant='warning'>Le cortège</Button></a>
      <a href="/galerie/2010/Dimanche/pendaison"> <Button className='p-3' variant='warning'>La pendaison</Button></a> 
      <a href="/galerie/2010/Dimanche/animations"> <Button className='p-3' variant='warning'>Les animations de l'après-midi</Button></a> 
      <a href="/galerie/2010/Dimanche/spectacle"> <Button className='p-3' variant='warning'>Spectacle de pyrotechnie</Button></a> 
      </div>
      <div className="bottom0">
      <Footer />
      </div>
    </div>
  )
}

export default NavPhotosDimanche