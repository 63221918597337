// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Footer() {
  return (
  <div> 
    <footer className='footer'>
      <ul>
        <h5>Contact : </h5>
          <li>info@legende-beaumont.be</li>
      </ul>
      <div>
      {/* <FontAwesomeIcon icon="facebook" /> */}
      </div>
    </footer>
    </div>   
  )
}

export default Footer