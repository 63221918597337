import Header from "../Home/Header"
import Footer from "../Home/Footer"

function Comite() {
  return (
<div className="core">
  <Header />
<div className="coreInfos"> 
  <div className="adresseAsbl">
    <div className="adresseAsbl__text">
    <h3>Adresse de l'ASBL </h3>
    <p>info@legende-beaumont.be</p>
    </div>
    </div>
    <div className="range">
      <div className="adresseGroupes">
        <h3>Animations</h3>
        <p>animations@legende-beaumont.be</p>
        </div>
        <div className="adresseGroupes">
          <h3>Inscription Cortège</h3>
          <p>cortege@legende-beaumont.be</p>
        </div>
        <div className="adresseGroupes">
          <h3>Franche-Foire</h3>
          <p>franchefoire@legende-beaumont.be </p>
        </div>
        </div>
        <div className="range">
        <div className="adresseGroupes">
          <h3>Tribune</h3>
          <p>tribune@legende-beaumont.be</p>
        </div>
        <div className="adresseGroupes">
          <h3>Webmaster</h3>
          <p>webmaster@legende-beaumont.be</p>
        </div>
        </div> 
        </div> 
  <Footer />  
</div>

  )
}

export default Comite