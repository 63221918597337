import React, {Component} from 'react'
import  MenuItems  from './MenuItems'
import './navBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

class Navbar extends Component {

  render() {
    return (
      <nav className='nav'>
        <ul className='navUl'>
          <div className='hamburger'>
          <FontAwesomeIcon icon={faBars} />
          </div>
         <div className='navLarge'>
          {MenuItems.map((item, index) => {
             return(
                <li key={index}> <a className={item.cName} href={item.url}>{item.title}</a></li>
             ) 
          })}
         </div>  
        </ul>
      </nav>
    )
  }
}

export default Navbar