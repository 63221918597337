import Header from "../Home/Header"
import Footer from "../Home/Footer"

import Button from 'react-bootstrap/Button'

function NavPhotosSamedi () {
  return (
    <div>
      <Header />
      <div className="core navBloc">
      <a href="/galerie/2010/Samedi/entree"> <Button className='p-3' variant='warning'>La joyeuse entrée</Button></a> 
      <a href="/galerie/2010/Samedi/serment"> <Button className='p-3' variant='warning'>Le serment vassalique</Button></a> 
      <a href="/galerie/2010/Samedi/erection"> <Button className='p-3' variant='warning'>L'érection de l'aigle doré</Button></a>
      <a href="/galerie/2010/Samedi/messe"> <Button className='p-3' variant='warning'>La messe des confréries</Button></a> 
      <a href="/galerie/2010/Samedi/retraite"> <Button className='p-3' variant='warning'>La retraite aux flambeaux</Button></a> 
      <a href="/galerie/2010/Samedi/jongleries"> <Button className='p-3' variant='warning'>Danseries et jongleries en l'honneur de l'Empereur</Button></a> 
 
      </div>
      <div className="bottom0">
      <Footer />
      </div>
    </div>
  )
}

export default NavPhotosSamedi