import { Component} from 'react'

import Header from '../Home/Header'
import Footer from '../Home/Footer'

import PhotoItems from './photoItems'

class Photos extends Component {
  constructor() {
    super()
    this.state = {justDate: ""}
    this.state = {renderList: []}
  }
  componentDidMount() {
    const justDate = parseInt(((window.location.pathname).split('/')[2]))
    this.setState({justDate});

    function rightDate(element) {
      if (element.date === justDate) {
        return true
      } else {
        return false
      }
    }
   let renderList = PhotoItems.filter(rightDate)
   this.setState({renderList})
    
  }
  render() {
    return (
      <div className='core'>
        <Header />
<div className='coreGalerie'>      
 <h2 className='titre bloc'>Photos {this.state.justDate}</h2>
        <div className='bloc bloc__photo'>
          {this.state.renderList.map((val) => {
            console.log(val.url)
      let id = val.id
      let url = val.url
      let lienImage = "/galerie/:" + (id)
      return (
        <div key={id}>
          <a href={lienImage}>
            <img className='photo' alt='Photo Cortege' src={url}></img>
          </a>
        </div>
      )
    })}
        </div>
        </div>  
        <Footer />
      </div>
  
    )
  }
  }
   

export default Photos