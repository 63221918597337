import Header from "../Home/Header"
import Footer from "../Home/Footer"

import Button from 'react-bootstrap/Button'

function NavPhotosJours2015 () {
  return (
    <div>
      <Header />
      <div className="core navBloc">
      <a href="/galerie/2015/Vendredi"> <Button className='p-3' variant='warning'>Vendredi</Button></a> 
      <a href="/galerie/2015/Samedi"> <Button className='p-3' variant='warning'>Samedi</Button></a> 
      <a href="/galerie/2015/Dimanche"> <Button className='p-3' variant='warning'>Dimanche</Button></a>
 
      </div>
      <div className="bottom0">
      <Footer />
      </div>
    </div>
  )
}

export default NavPhotosJours2015